.container {
  width: 100%;
  height: 100vh;
  max-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  header {
    width: 100%;
  }

  .content {
    height: 100%;
    max-width: 740px;
    margin-top: 60px;

    display: flex;
    align-items: center;
    flex-direction: column;

    .illustration {
      width: 100%;
      height: 50%;
      position: relative;
    }

    .title {
      width: 100%;
      height: 30vh;
      text-align: center;
      color: var(--neutral-dark);
      margin-top: 4rem;

      h4 {
        font-weight: bold;
        margin-bottom: 2rem;
      }

      button {
        max-width: 400px;
        margin: 2rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .content {
    margin: 60px 1rem !important;

    .title {
      margin: 0 !important;
    }
  }
}
