.headerContainer {
  width: 100%;
  z-index: 999;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  background: var(--white);

  display: flex;
  align-items: center;

  border-bottom: 1px solid var(--gray-100);

  height: 60px;
  color: var(--gray-3);

  p {
    margin-left: 2rem;
    padding: 0.25rem 0 0.25rem 2rem;
    border-left: 1px solid var(--gray-100);
  }

  span {
    margin-left: auto;
    text-transform: capitalize;
  }

  .logo {
    display: flex;
    margin: auto 0;
    justify-content: center;
    height: 2.5rem;
  }

  .linksDesktop {
    height: 100%;
    margin: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;

    a {
      position: relative;
      display: flex;
      -webkit-box-align: center;
      align-items: center;

      height: 100%;
      padding: 0px 8px;
      border-bottom: 2px solid transparent;
      font-size: 1rem;
      font-weight: 400;

      transition: color 0.2s ease 0s, border-color 0.2s ease 0s;

      color: var(--gray-7);
    }

    a.active {
      font-weight: 600;
      border-color: var(--primary-color);
      color: var(--font-color);
    }

    a + a {
      margin-left: 0.5rem;
    }
  }

  .linksMobile {
    display: none;
  }
}

.flat {
  filter: none !important;
  box-shadow: none !important;
}

.fixed {
  position: fixed;
}

@media (max-width: 767px) {
  .headerContainer {
    .linksDesktop {
      display: none;
    }

    .logo {
      flex: 1;
    }

    .linksMobile {
      display: flex;

      svg {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}

.userMenuBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  svg {
    &:first-of-type {
      border: 2px solid var(--gray-1);
      border-radius: 100%;
      margin-right: 5px;
      width: 2.5rem;
      height: 2.5rem;
      padding: 2px;
    }
  }

  span {
    margin-right: 5px;
  }
}
