.button {
  margin: 1rem auto;
  border: none;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  text-align: center;
  flex-direction: row;
  color: var(--white);
  text-decoration: none;
  font-size: 1rem;
  transition: 0.3s ease-in;
  cursor: pointer;
  overflow: hidden;

  &:first-letter {
    text-transform: uppercase;
  }

  &:hover,
  &:focus {
    transition: 0.3s ease-in;
    text-decoration: none;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--neutral-pure);

    &:hover,
    &:focus {
      background: var(--neutral-dark);
    }
  }

  a {
    color: var(--white);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
}

.button:not(.flatTextPrimary, .flatTextSecondary, .flatTextDanger) {
  min-width: 140px;
}

.primary {
  background: var(--primary-pure);

  &:hover {
    background: var(--primary-dark);
  }
}

.primaryOutline {
  background: none;
  border: 1px solid var(--primary-pure);
  color: var(--primary-pure);
  font-weight: bold;

  &:hover {
    background: var(--primary-pure);
    border: 1px solid var(--primary-pure);
    color: var(--white);
  }
}

.secondary {
  background: var(--secondary-pure);

  &:hover {
    background: var(--secondary-dark);
  }
}

.secondaryOutline {
  border: 1px solid var(--secondary-pure);
  background: transparent;
  color: var(--secondary-pure);
  font-weight: bold;

  &:hover {
    background: var(--secondary-pure);
    border: 1px solid var(--secondary-pure);
    color: var(--white);
  }
}

.whiteSecondary {
  border: 1px solid var(--secondary-pure);
  background: var(--white);
  color: var(--secondary-pure);

  &:hover {
    border: 1px solid var(--secondary-dark);
    background: var(--secondary-dark);
    color: var(--white);
  }
}

.outlineGrey {
  border: 1px solid var(--neutral-light);
  background: transparent;
  color: var(--neutral-light);

  &:hover {
    background-color: var(--neutral-light);
    border: 1px solid var(--neutral-light);
    color: var(--white);
  }

  &:disabled {
    background: var(--gray-3);
  }
}

// Social
.facebook {
  background: #4267b2;

  &:hover:not([disabled]),
  &:active {
    background: darken(#4267b2, 15%);
  }
}

.google {
  background: #4285f4;

  &:hover:not([disabled]),
  &:active {
    background: darken(#4285f4, 15%);
  }
}

.apple {
  background: #000;

  &:hover:not([disabled]),
  &:active {
    background: lighten(#000, 5%);
  }
}

// Flat Text
.flatTextPrimary,
.flatTextSecondary,
.flatTextDanger,
.link {
  font-weight: normal;
  border: none;
  background: none;

  &:hover {
    opacity: 0.6;
    text-decoration: underline;
  }
}

.flatTextPrimary {
  color: var(--primary-pure);
}

.flatTextSecondary {
  color: var(--secondary-pure);
}

.flatTextDanger {
  color: var(--error-color);
}
