.dropdownBtn {
  display: flex;
}

.dropdown {
  display: inline-block;
  position: relative;

  ul {
    list-style-type: none;
  }
}

.dropdownContent {
  margin-top: 0.2rem;
  display: none;
  position: absolute;
  background-color: var(--white);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.open {
    display: block;
  }

  a {
    color: var(--font-color);
    padding: 1rem;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: var(--gray-2);
    }
  }
}
