.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1024px;

  margin: auto;
  padding: 0 var(--padding-sm);

  .left {
    justify-content: left;
  }
  .center {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
