.header {
  height: var(--size-xl);
  width: 100vw;
  background-color: var(--backgroud-light-red);

  position: fixed;
  top: 0;
  z-index: 100;

  .content {
    width: 100%;
    max-height: var(--size-xl);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--backgroud-light-red);

    .burgerMenu {
      display: none;
      position: absolute;
      left: var(--padding-sm);
      top: calc(var(--size-xl) / 3);
    }

    .logo {
      max-width: var(--size-xl);
      max-height: inherit;
    }

    .links,
    .mainBtn,
    .mobileLinks,
    .mobileMainButton {
      max-height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .links,
    .mobileLinks {
      margin: 0 auto;

      a {
        padding: 0 var(--padding-sm);
      }

      a,
      button {
        font-weight: bold;
        color: var(--neutral-pure);
      }

      button {
        text-decoration: underline;
      }
    }
  }

  .mainBtn,
  .mobileMainBtn {
    button,
    a {
      font-weight: bold;
      max-width: 200px;
      padding: 0 var(--padding-sm);
    }
  }

  .mobileNavigation {
    display: none;
    width: 100vw;
    position: fixed;
    top: var(--size-xl);
    min-height: fit-content;
    background: var(--backgroud-light-red);
    box-shadow: var(--header-box-shadow);
    z-index: -1;
  }
}

@media (max-width: 768px) {
  .header {
    .content {
      justify-content: center;

      .burgerMenu {
        display: inline-block;
      }

      .links,
      .mainBtn {
        display: none;
      }
    }

    .mobileNavigation {
      display: inline-block;
    }
  }
}

@media (max-width: 350px) {
  .header {
    .logo {
      width: 55px;
    }
  }
}
